const ChevronsIcon = ({ className, ...props }: React.SVGProps<SVGSVGElement> & { className?: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 3000 2903'
    {...props}
    aria-label='Chevrons'
    role='img'
    className={className}
  >
    <path fill='#f05a22' d='M2224.1 1.5h-994.5L1996 1452.3 1229.6 2903h994.5l766.3-1450.7L2224.1 1.5z' />
    <path fill='#f05a22' d='M1004 0H9.6l766.3 1450.8L9.6 2901.5H1004l766.4-1450.7L1004 0z' />
  </svg>
);
export default ChevronsIcon;
