import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import Button from '@/components/ui/buttons/Button';
import { CuratedArticle } from '@/components/ui/cards/articles/CuratedArticle';
import ChevronsIcon from '@/components/ui/icons/ChevronsIcon';
import { useCuratedArticles } from '@/lib/api/queries/useCuratedArticles';

import { getDistinctId } from '@/stores/user/distinctId';

export default function CuratedArticles({ className }: { className?: string }) {
  const curatedIsOn = import.meta.env.PUBLIC_CURATED_ARTICLES_ENABLED === 'true';
  const distinctUserId = getDistinctId();
  const { data, isLoading, error } = useCuratedArticles(distinctUserId);

  const [currentIndex, setCurrentIndex] = useState(() => {
    return data && data.length > 0 ? 0 : -1;
  });

  const sliderRef = useRef<HTMLDivElement | null>(null);

  if (!curatedIsOn || !distinctUserId) {
    return null;
  }

  if (isLoading || error || data?.length === 0) {
    return null;
  }

  const updateButtons = (newIndex: number) => {
    const slider = sliderRef.current;
    if (slider) {
      const prevBtn = document.getElementById('curated-prev-btn') as HTMLButtonElement;
      const nextBtn = document.getElementById('curated-next-btn') as HTMLButtonElement;
      if (prevBtn && nextBtn) {
        prevBtn.disabled = newIndex === 0;
        nextBtn.disabled = newIndex >= (data?.length ?? 0) - 2;
      }
    }
  };

  const getSlideWidth = () => {
    const slider = sliderRef.current;
    if (!slider || !slider.children[0]) return 0;
    const article = slider.children[0] as HTMLElement;
    const gap = 26;
    return article.offsetWidth + gap;
  };

  const handleNext = () => {
    const slider = sliderRef.current;
    if (!slider) return;

    setCurrentIndex((prevIndex) => {
      const maxIndex = (data?.length ?? 0) - 2;
      const newIndex = Math.min(prevIndex + 1, maxIndex);
      slider.style.transform = `translateX(-${newIndex * getSlideWidth()}px)`;
      updateButtons(newIndex);
      return newIndex;
    });
  };

  const handlePrev = () => {
    const slider = sliderRef.current;
    if (!slider) return;

    setCurrentIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0);
      slider.style.transform = `translateX(-${newIndex * getSlideWidth()}px)`;
      updateButtons(newIndex);
      return newIndex;
    });
  };

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <div className='flex flex-row items-center justify-start lg:justify-center gap-2 px-4 py-2 bg-neutral-950 mb-4 lg:mb-6'>
        <ChevronsIcon className='w-8 h-8 text-hb-orange' />
        <div className='flex flex-col justify-center h-full'>
          <span className='font-medium text-white font-robotoCondensed text-2xl uppercase'>Curated For You</span>
          <div className='flex flex-row items-center justify-center gap-1'>
            <span className='text-xs lg:text-sm font-medium font-robotoCondensed uppercase'>
              <span className='text-neutral-400'>Articles Based On Your Interests</span>
              <span className='text-neutral-400'> - </span>
              <span className='text-orange-100'>Powered By GUIID</span>
            </span>
          </div>
        </div>
        <div className='hidden lg:flex lg:flex-row lg:items-center lg:justify-center lg:gap-2 ml-auto h-8'>
          <Button intent='navigationLight' size='navigation' className='z-5' id='curated-prev-btn' onClick={handlePrev}>
            <ChevronLeftIcon className='h-8 w-8' />
          </Button>
          <Button intent='navigationLight' size='navigation' className='z-5' id='curated-next-btn' onClick={handleNext}>
            <ChevronRightIcon className='h-8 w-8' />
          </Button>
        </div>
      </div>
      <div className='w-full overflow-x-hidden scroll-smooth'>
        <div className='flex flex-col lg:flex-row gap-4 lg:gap-6 transition-transform duration-300' ref={sliderRef}>
          {data?.map((item) => (
            <div key={item.data.id} className='w-full lg:w-[calc((100%-52px)/3)] flex-shrink-0'>
              <CuratedArticle item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
