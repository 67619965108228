import useSWR from 'swr';

import { api } from '@/lib/api/client';

import type { CuratedResponse, CuratedResponseError } from '@/types/api/curated';

const curatedArticlesFetcher = (data: { distinctUserId: string }) => {
  if (!data.distinctUserId) {
    return Promise.resolve(null);
  }
  return api.curated.post(data).then((res) => res.data);
};

export function useCuratedArticles(distinctUserId: string) {
  const { data, error } = useSWR<CuratedResponse, CuratedResponseError>(
    distinctUserId ? `/curated/${distinctUserId}` : null,
    () => curatedArticlesFetcher({ distinctUserId }),
  );

  return {
    data,
    isLoading: !error && !data,
    error,
  };
}
