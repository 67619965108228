import { Image } from '@unpic/react';
import _ from 'lodash';
import { BinocularsIcon } from '@/components/ui/icons/BinocularsIcon';
import type { CuratedArticleItem } from '@/types/api/curated';
import slugify from '@/utils/slugify';

interface CuratedArticleProps {
  item: CuratedArticleItem;
}

export const CuratedArticle = ({ item }: CuratedArticleProps) => {
  let recommendsMessage = '';

  if (item.matchingCategories.length === 0) {
    recommendsMessage = `Because Of Stories You've Read`;
  } else if (item.matchingCategories.length === 1) {
    recommendsMessage = `Because You Read About ${_.startCase(item.matchingCategories[0])}`;
  } else if (item.matchingCategories.length > 1) {
    recommendsMessage = `Because You Read About ${_.startCase(item.matchingCategories[0])} & ${_.startCase(item.matchingCategories[1])}`;
  }

  return (
    <div className='flex flex-col gap-4'>
      <a href={`${slugify(item.data.primaryCategory)}/${item.data.slug}`} target='_blank' rel='noopener noreferrer'>
        <div className='flex flex-row gap-4 items-center'>
          <Image
            src={item.data.featuredImage.link}
            alt={item.data.featuredImage.altText || item.data.title}
            layout='constrained'
            width={150}
            height={100}
          />
          <div className='flex flex-col gap-2'>
            <span className='text-xl font-medium font-robotoCondensed text-neutral-950 uppercase lg:line-clamp-2'>
              {item.data.title}
            </span>
            <span className='text-sm font-robotoCondensed font-medium text-neutral-800 uppercase'>
              BY {item.data.author}
            </span>
          </div>
        </div>
      </a>
      <div className='flex flex-row w-full px-4 py-2 bg-neutral-100 gap-3 items-center'>
        <BinocularsIcon className='w-8 h-auto text-hb-orange' />
        <div className='flex flex-row'>
          <span className='text-neutral-950 text-xs font-medium font-acuminPro uppercase'>
            <strong>GUIID Recommends:</strong> {recommendsMessage}
          </span>
        </div>
      </div>
    </div>
  );
};
